<script setup lang="ts">
import OrderBox from './_order-box.vue'

definePage({
  meta: {
    title: 'Rastreio'
  },
  props: true
})

const props = defineProps<{
  id: string
}>()

const router = useRouter()
const orderStore = useStore('order')

const goBack = () => {
  router.push('/tracking')
}

onMounted(() => {
  if(orderStore.items.length === 0) {
    orderStore.$actions.getTracking({
      document: props.id
    })
  }
})
</script>

<template>
  <div class="
    tw-grid
    tw-place-items-center
    tw-bg-gray-100
    tw-h-screen
  ">
    <div>
      <aeria-bare-button
        class="
          tw-mb-6
          tw-opacity-60
        "
        @click="goBack()"
      >
        <aeria-icon icon="arrow-circle-left">
          Voltar
        </aeria-icon>
      </aeria-bare-button>
      <aeria-panel>
        <div 
          v-if="orderStore.items.length > 0"
          class="tw-p-4"
        >
          <div class="
            tw-text-4xl
            tw-font-bold
            tw-mb-12
          ">
            Pedidos encontrados
          </div>
          <div class="
            tw-flex
            tw-flex-col
            tw-gap-y-4
          ">
            <order-box
              v-for="order in orderStore.items"
              :key="order._id"
              :order="order"
            ></order-box>
          </div>
        </div>
        <div 
          v-else
          class="tw-p-4"
        >
          <div class="
            tw-text-xl
            tw-font-bold
            tw-mb-12
            tw-text-center
          ">
            Nenhum pedido encontrado :/
        </div>
        </div>
      </aeria-panel>
    </div>
  </div>
</template>
