<script setup lang="ts">
import type { Property, CollectionItemWithId } from '@aeriajs/types'
import { generationPanel } from './store.js'

definePage({
  meta: {
    title: 'Remessas',
    icon: 'package',
    collection: 'shipment'
  }
})

const metaStore = useStore('meta')
const shipmentStore = useStore('shipment')

const updateTrackingPanel = ref(false)

const updateTrackingForm: Record<string, Property> = {
  date_min: {
    type: 'string',
    format: 'date',
    translate: true
  },
  date_max: {
    type: 'string',
    format: 'date',
    translate: true
  }
}

const generationForm: Record<string, Property> = {
  limit: {
    description: 'Limite',
    type: 'number',
    hint: 'Deixe sem preencher para etiquetar o restante dos pedidos'
  },
  hoursAgo: {
    description: 'Tempo de vida mínimo do pedido em horas',
    type: 'number',
    hint: `
    Caso preenchido, fará que somente pedidos pagos x horas atrás sejam
    etiquetados, de modo a evitar que pedidos com risco de estorno sejam
    despachados. Naturalmente, quanto menor for o valor mais etiquetas serão
    geradas.
    `
  },
  reshipmentOnly: {
    description: 'Gerar apenas reenvios',
    type: 'boolean'
  },
}

const makeShipments = async () => {
  await shipmentStore.$actions.make()
  metaStore.$actions.spawnModal({
    title: 'Sucesso',
    body: 'A geração das etiquetas está em andamento'
  })

  shipmentStore.$actions.getAll()
  generationPanel.value = false
}

const updateSpanTracking = async () => {
  await shipmentStore.$actions.updateSpanTracking()
  metaStore.$actions.spawnModal({
    title: 'Sucesso',
    body: 'A atualização dos rastreios está em andamento'
  })

  shipmentStore.$actions.getAll()
  updateTrackingPanel.value = false
}

const containsLateOrders = (shipment: CollectionItemWithId<'shipment'>, days: number) => {
  const d1 = new Date(shipment.created_at as Date).getTime() / 1000
  const d2 = new Date(shipment.reference_date as Date).getTime() / 1000
  const offset = 60 * 60 * 24 * days

  return d1 > d2 + offset
}
</script>

<template>
  <info-box warning>
    <template #title>
      <aeria-icon
        icon="warning"
        class="
          tw-text-[14pt]
          tw-font-[500]
        "

        style="--icon-color: red;"
      >
        Atenção
      </aeria-icon>
    </template>

    <p class="tw-m-0">
    Agora um ícone e uma cor são associados à coluna <b>Data de referência</b> para indicar PLPs que contém pedidos muito antigos referentes à data de criação da PLP. As cores vermelha e laranja indicam que há pedidos muito antigos na PLP, o que <strong>pode</strong> decorrer de algum problema operacional. Se muitas PLPs exibirem status vermelhos ou laranjas, é provável que um erro operacional tenha ocorrido, como por exemplo busca retroativa de um prazo indevido na integração.
    </p>

    <ul>
      <li><aeria-icon icon="check" style="--icon-color: green" /> pedidos dentro dos últimos 5 dias</li>
      <li><aeria-icon icon="warning" style="--icon-color: orange" /> pedidos com data superior a 5 dias</li>
      <li><aeria-icon icon="siren" style="--icon-color: red" /> pedidos com data superior a 10 dias</li>
    </ul>

  </info-box>

  <aeria-crud collection="shipment">
    <template #actions>
      <aeria-button
        icon="gear"
        @click="generationPanel = true"
      >
        Gerar etiquetas
      </aeria-button>
      <aeria-button
        icon="scan"
        @click="updateTrackingPanel = true"
      >
        Atualizar rastreios
      </aeria-button>
    </template>

    <template #row-reference_date="{ row, column }">
      <aeria-icon v-if="containsLateOrders(row, 10)" icon="siren" class="caution-date">
        {{ formatDateTime(row[column]) }}
      </aeria-icon>
      <aeria-icon v-else-if="containsLateOrders(row, 5)" icon="warning" class="warning-date">
        {{ formatDateTime(row[column]) }}
      </aeria-icon>
      <aeria-icon v-else icon="check" class="okay-date">
        {{ formatDateTime(row[column]) }}
      </aeria-icon>
    </template>

    <template #row-status="{ row, column }">
      <status-badge :color="shipmentStore.$actions.getComputedColor(row[column])">
        {{ t(row[column]||'-') }}
      </status-badge>
    </template>

    <template #row-shipping_profile="{ row, column }">
      <div class="
        tw-flex
        tw-items-center
        tw-gap-1
      ">
        <extra-icon :name="row[column].provider"></extra-icon>
        <div>
          {{ row[column].name }}
        </div>
      </div>
    </template>

  </aeria-crud>

  <aeria-panel
    close-hint
    fixed-right
    title="Gerar etiqueta"
    v-model="generationPanel"
    @overlay-click="generationPanel = false"
  >
    <aeria-form
      v-model="shipmentStore.generation"
      v-bind="{
        form: generationForm,
      }"
    ></aeria-form>

    <template #footer>
      <aeria-button
        variant="transparent"
        @click="generationPanel = false"
      >
        Cancelar
      </aeria-button>
      <aeria-button
        large
        @click="makeShipments"
      >
        Gerar
      </aeria-button>
    </template>
  </aeria-panel>

  <aeria-panel
    close-hint
    fixed-right
    title="Atualizar rastreios por período"
    v-model="updateTrackingPanel"
    @overlay-click="updateTrackingPanel = false"
  >
    <aeria-form
      v-model="shipmentStore.updateTracking"
      v-bind="{
        form: updateTrackingForm,
      }"
    ></aeria-form>

    <template #footer>
      <aeria-button
        variant="transparent"
        @click="updateTrackingPanel = false"
      >
        Cancelar
      </aeria-button>
      <aeria-button
        large
        @click="updateSpanTracking"
      >
        Enviar
      </aeria-button>
    </template>
  </aeria-panel>
</template>

<style scoped lang="less">
.caution-date {
  --icon-color: red;
  color: red;
}

.warning-date {
  --icon-color: orange;
  color: orange;
}

.okay-date {
  --icon-color: green;
  color: green;
}

ul {
  display: flex;
  flex-direction: column;
  gap: .2rem;
  padding: 0;
}
ul > li {
  display: flex;
  gap: .6rem;
}

ul > li > *:first-child:after {
  content: ': ';
}
</style>

