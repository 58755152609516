<script setup lang="ts">
import { simulationPanel } from './store'
import type { Property } from '@aeriajs/types'

definePage({
  meta: {
    title: 'Tabelas de frete',
    icon: 'table',
    collection: 'shippingTable',
  },
})

type Result = {
  cost: number
  profile: {
    name: string
  }
  service: {
    description: string
  }
}

const shippingTableStore = useStore('shippingTable')
const simulationResult = ref<Array<Result>>([])

const simulationForm: Record<string, Property> = {
  zipcode: {
    description: 'Código postal',
    type: 'string',
    mask: '#####-###'
  },
  weight: {
    description: 'Peso',
    type: 'number',
    hint: 'Em gramas'
  },
}

const simulate = async () => {
  const { result } = await shippingTableStore.$actions.simulate()
  if( result ) {
    simulationResult.value = result
  }
}
</script>

<template>
  <aeria-crud collection="shippingTable">
    <template #actions>
      <aeria-button
        icon="magnifying-glass"
        @click="simulationPanel = true"
      >
        Simular frete
      </aeria-button>
    </template>

    <template #row-profile="{ row, column }">
      <div class="
        tw-flex
        tw-items-center
        tw-gap-1
      ">
        <extra-icon v-if="row[column]" :name="row.profile?.provider"></extra-icon>
        <div>{{ row[column]?.name || '-' }}</div>
      </div>
    </template>

  </aeria-crud>

  <aeria-panel
    close-hint
    fixed-right
    title="Simulação de frete"
    v-model="simulationPanel"
    @overlay-click="simulationPanel = false"
  >
    <div class="
      tw-flex
      tw-flex-col
      tw-gap-8
    ">
      <aeria-form
        v-model="shippingTableStore.simulation"
        :form="simulationForm"
      ></aeria-form>

      <div
        v-if="simulationResult.length > 0"
        class="
          tw-flex
          tw-flex-col
          tw-gap-y-4
      ">
        <div
          v-for="(result, index) in simulationResult"
          :key="`result-${index}`"
          class="
            tw-flex
            tw-flex-col
            tw-gap-2
            tw-border
            tw-shadow-md
            tw-rounded
            tw-p-6
            first:tw-border-green-500
            first:tw-bg-green-50
        ">
          <div>Custo: {{ result.cost }}</div>
          <div>Perfil: {{ result.profile.name }}</div>
          <div>Serviço: {{ result.service.description }}</div>
        </div>
      </div>

      <div v-else>
        Não foram retornados resultados.
      </div>
    </div>

    <template #footer>
      <aeria-button
        variant="transparent"
        @click="simulationPanel = false; simulationResult = []"
      >
        Cancelar
      </aeria-button>
      <aeria-button 
        large
        @click="simulate"
      >
        Simular
      </aeria-button>
    </template>
  </aeria-panel>
</template>

