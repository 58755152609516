import { registerStore, createCollectionStore } from 'aeria-ui'

import { createOrderStructure } from './order'
export * from './order'
export * from './customer'
export * from './product'
export * from './shipment'
export * from './shippingTable'
export * from './integration'

export const orderReview = registerStore((manager) => createCollectionStore({
  $id: 'order',
  ...createOrderStructure()
}, manager))

export const reshipment = registerStore((manager) => createCollectionStore({
  $id: 'order',
  ...createOrderStructure()
}, manager))
