<script setup lang="ts">
import { getGlobalStateManager, useI18n } from 'aeria-ui';
import { historyPanel } from './store'
import { formatDocument, spreadsheetTemplates, viewIntegration } from '../../../helpers'
import type { CollectionItemWithId } from '@aeriajs/types';
import { getBondedValues } from '../../../helpers/comissionHelper';
import { Buffer } from 'buffer';
import CustomHeaders from './custom-headers.vue';
import { Header, spreadsheetHeaders } from '../../../../../api/src/utils/spreadsheetHeaders';
const { t } = useI18n()

let retainedValue: ReturnType<typeof getBondedValues>

definePage({
  meta: {
    title: 'Pedidos',
    icon: 'shopping-cart',
    collection: 'order'
  },
})

const router = useRouter()
const orderStore = useStore('order')
const metaStore = useStore('meta')
const userStore = useStore('user')

const manager = getGlobalStateManager()

const action = useAction(orderStore, router, manager)
const [call, _bus] = action

const errorPanel = ref<any>(false)
const orderHistoryItems = ref<CollectionItemWithId<"order">[]>([])

const orderHistory = computed<CollectionItemWithId<"order">[]>(() => {
  if (!orderHistoryQuery.value) {
    return orderHistoryItems.value
  }

  return orderHistoryItems.value.filter((order: CollectionItemWithId<"order">) => {
    const exp = new RegExp(orderHistoryQuery.value, 'i')
    return exp.test(order.customer.name)
      || exp.test(order.receiver_name as string)
      || exp.test(order.token as string)
      || exp.test(order.products.map(p => p.name).join(""))
  })
})
const orderHistoryQuery = ref('')

const openHistoryPanel = async () => {
  await userStore.$actions.authenticate({ revalidate: true })
  const orderIds: string[] = userStore.currentUser.order_history?.filter((order: CollectionItemWithId<"order">) => typeof order === 'string')

  console.log({
    c: userStore.currentUser,
    orderIds,
  })

  if (orderIds?.length > 0) {
    const { result: { data: orders } } = await orderStore.$functions.getAll({
      filters: {
        _id: {
          $in: orderIds
        }
      },
      limit: 100,
    }) as { result: { data: CollectionItemWithId<"order">[] } }

    orderHistoryItems.value.splice(0)
    orderHistoryItems.value.push(...orderIds.map(id => orders.find(order => order._id === id)).filter(order => !!order))
  }

  historyPanel.value = true
}

const clickReport = async (items: any) => {
  const { error, result } = await aeria.order.report.POST({
    filters: items
  });

  if (!error && result) {
    const blob = new Blob([result], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'report.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
};

const importSpreadsheetData = reactive({
  confirmationPanel: false,
  columnOrderPanel: false,
  flatOrders: [] as any[],
  spreadsheet: null as { file: File } | null,
  integration: {} as CollectionItemWithId<"integration">,
  isImporting: false,
  commission_type: <const>"fixed"
})

const warningsPanel = reactive({
  panel: false,
  text: "",
  headerWarnings: [] as [string, Record<"header" | "reason" | "token", any>[]][]
})

const checkSpreadsheet = async () => {
  if (!importSpreadsheetData.spreadsheet?.file.type) {
    metaStore.$actions.spawnModal({
      title: "Ops...",
      body: "Escolha um arquivo primeiro."
    })
    return
  }

  const { result, error } = await aeria.integration.validateSpreadsheet.POST({
    base64: Buffer.from(await importSpreadsheetData.spreadsheet.file.arrayBuffer()).toString("base64"),
    fileType: importSpreadsheetData.spreadsheet.file.type as any,
    columns: spreadSheetColumns.value
  })

  if (error) {
    metaStore.$actions.spawnModal({
      title: "Ops...",
      body: error.message || error.code
    })

    return
  }

  importSpreadsheetData.flatOrders = result.flatOrders.sort((a, b) => Date.parse(b.date_created) - Date.parse(a.date_created));

  const [dateMin, dateMax] =
    [new Date(importSpreadsheetData.flatOrders.toReversed().find(o => o.date_created)?.date_created).toLocaleDateString(),
    new Date(importSpreadsheetData.flatOrders.find(o => o.date_created).date_created)?.toLocaleDateString()]

  openWarnings(`Pedidos de ${dateMin} até ${dateMax} serão importados, tem certeza que quer continuar ?`, result.warnings)
}

const openWarnings = (text: string, warnings: any) => {
  warningsPanel.panel = true
  warningsPanel.text = text

  const warningEntries = Object.entries(warnings).reduce((acc: any[], value: any[]) => {
    value[1] = value[1].map((warning: any) => ({ ...warning, reason: t(warning.reason, { capitalize: true }) }))
    acc.push(value)
    return acc
  }, [])

  if (warningEntries.length > 0) {
    warningsPanel.headerWarnings = []
    warningsPanel.headerWarnings.push(...warningEntries as any)
  }
}

const importSpreadsheet = async () => {
  warningsPanel.panel = false;
  if (!importSpreadsheetData.integration?._id) {
    metaStore.$actions.spawnModal({
      title: "Ops...",
      body: "Nenhuma integração selecionada."
    })

    return
  }

  if (!importSpreadsheetData.commission_type) {
    metaStore.$actions.spawnModal({
      title: "Ops...",
      body: "Tipo do valor da comissão inválido."
    })
    return
  }

  importSpreadsheetData.isImporting = true;

  try {
    const { result, error } = await aeria.integration.importSpreadsheet.POST({
      flatOrders: importSpreadsheetData.flatOrders,
      integrationId: importSpreadsheetData.integration._id,
      commissionType: importSpreadsheetData.commission_type,
      columns: spreadSheetColumns.value
    })


    importSpreadsheetData.isImporting = false;

    if (error) {
      metaStore.$actions.spawnModal({
        title: "Falha",
        body: (error.code || error.message)
      })

      return
    }

    importSpreadsheetData.flatOrders = [];

    metaStore.$actions.spawnPrompt({
      title: "Sucesso",
      body: `Importação adicionada á fila como tarefa (${result.products} produtos e ${result.plans} planos novos serão gerados, ${result.errors.length} erros)`,
      options: {
        close: {
          title: "Fechar"
        },
        ...(result.errors.length > 0 && {
          seeErrors: {
            title: "Ver erros",
            click: () => {
              metaStore.$actions.spawnModal({
                title: "Erros",
                body: result.errors.join(",\n")
              })
            },
            variant: 'danger'
          },
        }),
      }
    })
  } catch (error) {
    console.error(error)

    metaStore.$actions.spawnModal({
      title: "Falha",
      body: "Erro desconhecido"
    })
  }

  importSpreadsheetData.isImporting = false;
  importSpreadsheetData.confirmationPanel = false;
}

const spreadSheetColumns = ref<Header[]>([...spreadsheetHeaders.filter(h => h !== "empty")])
watch(() => importSpreadsheetData.integration, (newValue) => {
  if (newValue?.platform && newValue.platform in spreadsheetTemplates) {
    spreadSheetColumns.value = [...spreadsheetTemplates[newValue.platform]]
  }
})
</script>

<template>
  <teleport v-if="errorPanel" to="main">
    <aeria-panel v-model="errorPanel" float close-hint title="Pedido com erro" @overlay-click="errorPanel = false">
      <div v-if="errorPanel.shipping_profile">
        Esse pedido apresentou erro ao ser enviado pela transportadora <b>{{ errorPanel.shipping_profile.provider }}</b>
        no perfil <b>{{ errorPanel.shipping_profile.name }}</b>.
        <div class="
          tw-text-red-600
          tw-my-4
        ">
          {{ errorPanel.error }}
        </div>
      </div>

      <template #footer>
        <aeria-button large icon="pencil" @click="call({ action: 'spawnEdit' })(errorPanel); errorPanel = false">
          Editar pedido
        </aeria-button>
      </template>
    </aeria-panel>
  </teleport>

  <failed-orders-warning v-if="router.currentRoute.value.query.section !== 'failed'"></failed-orders-warning>

  <!-- Avisos caso importação da planilha tenha valores errados -->
  <aeria-prompt style="--panel-min-width: 55rem;" v-model="warningsPanel.panel" float close-hint title="Aviso"
    @overlay-click="warningsPanel.panel = false" :options="{
      confirm: {
        title: 'Confirmar',
        click: importSpreadsheet,
      },
      cancel: {
        title: 'Cancelar',
        click: () => { warningsPanel.panel = false },
        variant: 'danger'
      }
    }">
    <div class="tw-w-[50rem]">
      <h3>
        {{ warningsPanel.text }}
      </h3>
      <div v-if="warningsPanel.headerWarnings.length > 0" v-for="[header, warnings] in warningsPanel.headerWarnings">
        <aeria-icon style="--icon-size: 1.2rem" icon="warning">
          <h3>Erros na coluna {{ t(header, { capitalize: true }) }}</h3>
        </aeria-icon>
        <aeria-table v-bind="{
          columns: {
            value: {
              description: 'Valor',
              type: 'string'
            },
            reason: {
              description: 'Motivo',
              type: 'string'
            },
            token: {
              description: 'Código do pedido',
              type: 'string'
            },
          },
          rows: warnings
        }" />
      </div>
    </div>
  </aeria-prompt>

  <aeria-crud collection="order" :action="action">
    <template #actions>
      <aeria-button icon="calendar" @click="openHistoryPanel">
        Histórico
      </aeria-button>

      <aeria-button v-if="Object.keys(orderStore.activeFilters).length > 0" icon="arrow-line-down"
        @click="clickReport(orderStore.activeFilters)">
        Relatório
        <aeria-badge>
          Novo!
        </aeria-badge>
      </aeria-button>

      <aeria-button icon="grid-four" @click="importSpreadsheetData.confirmationPanel = true">
        Importar planilha
        <aeria-badge>
          Novo!
        </aeria-badge>
      </aeria-button>
    </template>

    <template #row-local_id="{ row, column }">
      <div class="
        tw-flex
        tw-flex-col
        tw-gap-1
      ">
        <div>{{ row[column] }}</div>
        <div class="
          tw-text-[8.5pt]
          tw-opacity-60
        ">
          {{ row.token }}
        </div>
      </div>
    </template>

    <template #row-customer="{ row, column }">
      <div class="
        tw-flex
        tw-flex-col
        tw-gap-1
      ">
        <div>{{ row[column]?.name || "-" }}</div>
        <div class="
          tw-text-[8.5pt]
          tw-opacity-60
        ">
          {{ formatDocument(row[column]?.document) }}
        </div>
      </div>
    </template>

    <template #row-status="{ row, column }">
      <status-badge :color="orderStore.$actions.getComputedColor(row[column])">
        {{ t(row[column] || '-') }}
      </status-badge>
    </template>

    <template #row-products="{ row, column }">
      <div v-if="row.content_description">
        {{ row.content_description }}
      </div>

      <span v-else class="
          tw-flex
          tw-items-center
          tw-gap-1
      ">
        <extra-icon v-clickable @click="() => viewIntegration(row.integration)"
          :name="row.integration?.platform"></extra-icon>
        <div>
          {{ row[column]?.map((product: any) => product.name).join(', ') }}
        </div>
      </span>

    </template>

    <template #row-tracking_code="{ row, column }">
      <div v-if="row[column]" class="
          tw-flex
          tw-items-center
          tw-gap-1
      ">
        <extra-icon v-if="row.shipping_profile" :name="row.shipping_profile.provider"></extra-icon>
        <div>{{ row[column] }}</div>
      </div>

      <div v-else-if="row.error" class="
          tw-flex
          tw-items-center
          tw-gap-1
      ">
        <extra-icon v-if="row.shipping_profile" :name="row.shipping_profile.provider"></extra-icon>

        <aeria-button small variant="transparent" icon="exclamation-mark" @click="errorPanel = row">
          Erro!
        </aeria-button>
      </div>

      <div v-else>
        -
      </div>
    </template>

    <template #row-error="{ row, column }">
      <div class="
        tw-flex
        tw-gap-2
      ">
        <extra-icon v-if="row.shipping_profile?.provider" :name="row.shipping_profile.provider"></extra-icon>
        <div>{{ row[column] }}</div>
      </div>
    </template>

    <template #row-retained_amount="{ row, column }">
      <div v-if="retainedValue = getBondedValues({
        isRetained: true,
        order: row
      })" :class="retainedValue.haveMessage ? 'tw-text-[0.7rem]' : ''">{{ retainedValue.value ?? '-' }}</div>
    </template>
  </aeria-crud>

  <aeria-panel v-loading="importSpreadsheetData.isImporting" close-hint fixed-right title="Importar planilha de pedidos"
    v-model="importSpreadsheetData.confirmationPanel" @overlay-click="importSpreadsheetData.confirmationPanel = false">
    <aeria-button style="
    font-weight: 700; 
    font-size: 10.5pt; 
    --icon-size: 14pt;" variant="alt" class="tw-w-48 tw-whitespace-nowrap" icon="table"
      @click.prevent="importSpreadsheetData.columnOrderPanel = true">
      Ordem das colunas
    </aeria-button>
    <aeria-form v-model="importSpreadsheetData" v-bind="{
      form: {
        spreadsheet: {
          description: 'Arquivo (CSV ou XLSX)',
          $ref: 'file',
          accept: ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
        },
        integration: {
          $ref: 'integration',
          description: 'Integração',
          indexes: ['name'],
        },
        commission_type: {
          description: 'Tipo do valor da comissão',
          enum: ['percentage', 'fixed'],
          translate: true,
        }
      },
    }" />

    <template #footer>
      <aeria-button variant="transparent" @click="importSpreadsheetData.confirmationPanel = false">
        Cancelar
      </aeria-button>
      <aeria-button large @click.prevent="checkSpreadsheet">
        Importar
      </aeria-button>
    </template>
  </aeria-panel>

  <!-- importSpreadsheetData.columnOrderPanel -->
  <aeria-panel style="--max-width: 90%;" float close-hint title="Ordem obrigatória das colunas"
    v-model="importSpreadsheetData.columnOrderPanel" @overlay-click="importSpreadsheetData.columnOrderPanel = false">
    <div>
      <h4>A planilha deve estar ordenada da seguinte forma <u>caso contrário os pedidos atualizados terão informações
          incorretas</u>, caso a planilha tenha campos que não estão presentes abaixo, deixe a coluna como "Vazio" pois
        o
        valor não irá ser utilizado.</h4>
      <custom-headers v-model="spreadSheetColumns" :platform="importSpreadsheetData.integration.platform" />
    </div>
  </aeria-panel>

  <aeria-panel close-hint float title="Histórico de pedidos abertos" v-model="historyPanel"
    @overlay-click="historyPanel = false">
    <aeria-input class="tw-w-full" v-model="orderHistoryQuery" :property="{
      type: 'string',
      icon: 'magnifying-glass',
      placeholder: 'Token, nome do cliente ou nome do produto'
    }"></aeria-input>

    <div class="
      tw-flex
      tw-flex-col
      tw-gap-3
      tw-overflow-scroll
      lg:tw-w-[40rem]
    ">
      <div v-clickable v-for="order in orderHistory" :key="order._id" class="
          tw-transition-all
          tw-flex
          tw-flex-col
          tw-text-[11pt]
          tw-gap-3
          tw-p-4
          tw-border
          tw-rounded-lg
          hover:tw-shadow-lg
        " @click="historyPanel = false; router.push({
          name: '/dashboard/order/view/[id]',
          params: {
            id: order._id
          }
        })">
        <div class="
          tw-flex
          tw-items-center
          tw-justify-between
          tw-gap-4
        ">
          <div>
            <div class="
              tw-font-[500]
            ">
              {{ order.local_id }}
            </div>
            <div class="
              tw-opacity-60
              tw-text-[9pt]
            ">
              {{ order.token }}
            </div>
          </div>

          <status-badge :color="orderStore.$actions.getComputedColor(order.status)">
            {{ t(order.status || '-') }}
          </status-badge>
        </div>

        <div>
          <div>{{ order.customer.name }}</div>
          <div>Criado em: {{ formatDateTime(order.date_created, true) }}</div>
        </div>


        <div class="tw-text-[10pt]">
          <div class="
            tw-flex
            tw-items-center
            tw-gap-1
          ">
            <extra-icon size="1rem" :name="order.integration?.platform"></extra-icon>
            <div>
              {{ order.products.map(product => product.name).join(', ') }}
            </div>
          </div>

          <div v-if="order.tracking_code" class="
              tw-flex
              tw-items-center
              tw-gap-1
              tw-text-[10pt]
          ">
            <extra-icon size="1rem" :name="order.shipping_profile?.provider"></extra-icon>
            <div>{{ order.tracking_code }}</div>
          </div>
        </div>

      </div>
    </div>
  </aeria-panel>
</template>

<style scoped>
.panel {
  white-space: pre-line !important;
}
</style>
