<script setup lang="ts">
definePage({
  meta: {
    title: 'Suporte',
    icon: 'headphones'
  }
})
</script>

<template>
  <section>
    <h2>Tópicos de ajuda</h2>

    <aeria-accordion enumerate :headers="{
      'whatsapp-troubleshooting': 'Problemas ao conectar o WhatsApp',
      'integrate-with-platforms': 'Como integrar com plataformas de pagamento',
      'create-a-funnel': 'Como criar um funil',
      'create-a-broadcast': 'Como criar um broadcast',
      'create-conversation-tags': 'Como criar rótulos para atendimentos',
    }">
      <template #whatsapp-troubleshooting>
        Placeholder...
      </template>

      <template #integrate-with-platforms>
        Placeholder...
      </template>

      <template #create-a-funnel>
        Placeholder...
      </template>

      <template #create-a-broadcast>
        Placeholder...
      </template>

      <template #create-conversation-tags>
        Placeholder...
      </template>
    </aeria-accordion>
  </section>

  <section>
    <h2>Central de atendimento</h2>
    <p>
      Não encontrou o que queria, ou simplesmente gostaria de falar com uma pessoa real? <br/>
      Entre em contato com um dos nossos canais de suporte:
    </p>
  </section>
</template>
