<script setup lang="ts">
import { t } from 'aeria-ui';

definePage({
  meta: {
    title: 'Extrato'
  }
})

const statementStore = useStore('statement')
const userStore = useStore('user')
const router = useRouter()
const metaStore = useStore('meta')

const tableRows = ref([] as any[])

onMounted(async () => {
    const {error, result: statements} = await statementStore.$actions.getAll({
        filters: {
            owner: userStore.currentUser._id
        }
    })
    if( !statements ) {
      metaStore.$actions.spawnModal({ title: "Erro!", body: "Erro ao puxar extrato"})
      return
    }
    tableRows.value = statements.map(st => {
        return {
            date: new Date(st.created_at).toUTCString(),
            description: st.description,
            accounting: t(st.accounting),
            order: st.order?.token ? st.order : undefined,
            value: st.value,
        }
    })
})
</script>

<template>
    <aeria-table>
  <template #thead>
    <tr class="[&>*]:tw-text-center">
      <th>Data</th>
      <th>{{statementStore.properties.accounting.description}}</th>
      <th>{{statementStore.properties.description.description}}</th>
      <th>{{statementStore.properties.order.description}}</th>
      <th>{{statementStore.properties.value.description}}</th>
    </tr>
  </template>
  <template #tbody>
    <tr class="[&>*]:tw-font-normal [&>*]:tw-text-[0.85rem] [&>*]:tw-border"
      v-for="(row, index) in tableRows"
      :key="`row-${index}`"
    >
      <th>{{ row.date }}</th>
      <th>{{ row.accounting }}</th>
      <th>{{ row.description }}</th>
      <th>
        <div class="tw-flex tw-flex-row tw-items-center tw-justify-center">
            {{ row.order ? `${row.order.customer?.name} - ${row.order.token}` : '-' }}
            <aeria-button class="tw-ml-2" v-if="row.order" variant="transparent" icon="magnifying-glass" @click.prevent="router.push('/dashboard/order/view/' + row.order?._id)">Ver</aeria-button>
        </div>
      </th>
      <th>{{ row.value }}</th>
    </tr>
  </template>
</aeria-table>
</template>
