<script setup lang="ts">
import { DashboardLayout } from 'aeria-app-layout'

const metaStore = useStore('meta')
const router = useRouter()
</script>

<template>
  <dashboard-layout class="dashboard">
    <template #super>
      <aeria-icon
        v-clickable
        icon="headphones"
        @click="router.push('/dashboard/support')"
      >
        Suporte
      </aeria-icon>

      <aeria-icon
        v-clickable
        :icon="
          metaStore.theme === 'dark'
            ? 'moon'
            : 'sun'
        "
        @click="metaStore.theme === 'dark'
          ? metaStore.$actions.saveTheme('default')
          : metaStore.$actions.saveTheme('dark')
        "
        >
      </aeria-icon>
    </template>
  </dashboard-layout>
</template>

