<script setup lang="ts">
definePage({
  meta: {
    title: 'Perfil de logística',
    icon: 'tray'
  }
})
</script>

<template>
  <aeria-crud collection="shippingProfile">
    <template #row-provider="{ row, column }">
      <div class="
        tw-flex
        tw-items-center
        tw-gap-1
      ">
        <extra-icon :name="row.provider"></extra-icon>
        <div>
          {{ row[column] }}
        </div>
      </div>
    </template>
  </aeria-crud>
</template>
