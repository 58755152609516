<script setup lang="ts">
import { AeriaInput } from 'aeria-ui';

definePage({
  meta: {
    title: 'Rastreio'
  }
})

const router = useRouter()
const metaStore = useStore('meta')
const orderStore = useStore('order')

const customerDocument = ref('')
const isWaiting = ref(false)

const getTracking = async () => {
  isWaiting.value = true;
  try {
    const { error } = await orderStore.$actions.getTracking({
      document: customerDocument.value
    })

    if (error) {
      metaStore.$actions.spawnToast({
          text: error.message || error.code,
          icon: "x-circle"
      })
      isWaiting.value = false;
      return
    }
    
    router.push(`/track-by-id/${customerDocument.value}`)
  } catch( err: any ) {
    metaStore.$actions.spawnModal({
      title: 'Houve um erro',
      body: err.message
    })
  }
  isWaiting.value = false;
}
</script>

<template>
  <div class="
    tw-grid
    tw-place-items-center
    tw-bg-gray-100
    tw-h-screen
  ">
    <aeria-panel>
      <div class="
        tw-flex
        tw-flex-col
        tw-gap-y-6
        tw-self-center
        lg:tw-w-[28em]
      ">
        <div class="
          tw-font-bold
          tw-text-2xl
          tw-pb-4
        ">
          Rastreie o seu pedido
        </div>
        <aeria-input
          v-model="customerDocument"
          v-bind="{
            variant: 'light',
            property: {
              type: 'string',
              placeholder: '000.000.000-00',
              mask: [
                '###.###.###-##',
                '##.###.###/####-##'
              ]
            }
          }"
        >
          CPF/CNPJ
        </aeria-input>
        <aeria-button
          :disabled="customerDocument.length < 11 || isWaiting"
          class="tw-w-full"
          @click="getTracking"
        >
          <div class="
            tw-w-full
            tw-py-2
          ">
            {{ !isWaiting ? "Buscar pedidos" : "Aguarde..." }}
          </div>
        </aeria-button>
      </div>
    </aeria-panel>
  </div>
</template>
