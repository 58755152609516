<script setup lang="ts">
definePage({
  meta: {
    title: 'Dashboard',
    icon: 'gauge'
  }
})
</script>

<template>
  <div class="
    tw-font-semibold
    tw-text-2xl
    tw-mb-6
  ">
    Bem-vindo novamente, {{ currentUser.name.split(' ')[0] }}.
  </div>

  <failed-orders-warning></failed-orders-warning>
</template>
