import { registerStore, createCollectionStore } from 'aeria-ui'

export const integration = registerStore((context) => createCollectionStore({
  $id: 'integration',
  state: {
    getSpan: {
      date_min: new Date(),
      date_max: new Date(),
      mapping: "orderpaid"
    }
  }
}, context))
