<script setup lang="ts">
import { API_URL, useI18n } from 'aeria-ui';
import { formatCurrency } from '../../../helpers'
import { aeria } from 'aeria-sdk'

const { VITE_PROFIT_PERCENTAGE } = import.meta.env

definePage({
  meta: {
    title: 'Postagem manual',
    icon: 'calculator',
  }
})

type Props = {
  modality:
  | 'products'
  | 'content'
}

defineProps<Props>()

const { t } = useI18n()

const shippingTableStore = useStore('shippingTable')
const productPlanStore = useStore('productPlan')
const integrationStore = useStore('integration')
const metaStore = useStore('meta')
const router = useRouter()
const userStore = useStore('user')

const formData = reactive({
  weight: 0,
  declared_value: 0,
  height: 0,
  width: 0,
  content_description: '',
  receiver_name: '',
  receiver_document: '',
  receiver_email: '',
  receiver_phone: '',
  receiver_country: 'BR',
  receiver_address: '',
  receiver_number: '',
  receiver_district: '',
  receiver_state: '',
  receiver_zipcode: '',
  receiver_city: '',
  receiver_complement: '',
  is_reshipment: false,
  order_token: '',
})

//Inicializa os campos os tenha na query
const query = computed(() => router.currentRoute.value.query)

for (const key in query.value) {
  if (key in formData) {
    (formData as any)[key] = query.value[key as keyof typeof query.value]
  }
}

onMounted(async () => {
  //Faz a conversão do array na query que vem separado por vírgula
  if (typeof query.value.products === 'string') {
    for (const productId of query.value.products.split(',')) {
      const { result: product } = await productPlanStore.$actions.get({
        filters: {
          product: productId
        }
      })
      selectedProducts.value.push(product)
    }
  }
  //Se tiver esses campos na query provavelmente é um reenvio
  if (query.value.content_description || query.value.products) {
    formData.is_reshipment = true

    if (query.value.integration) {
      const { result: integration } = await integrationStore.$actions.get({
        filters: {
          _id: query.value.integration
        }
      })
      selectedIntegrations.value.push(integration)
    }
  }
})

const defaultFormData: typeof formData = { ...formData }

const shipmentId = ref<string | null>('')
const simulation = ref<any[]>([])
const selectedProducts = ref<any[]>([])
const selectedIntegrations = ref<any[]>([])
const inputRenderer = ref(0)

const section = computed(() => {
  return query.value.modality || 'content'
})

const freshOption = {
  cost: 0,
  provider: '',
  service: {
    _id: ''
  },
  profile: {
    _id: '',
    business: {
      name: ''
    },
    business_address: {
      address: '',
      number: '',
      district: '',
      city: '',
      state: '',
      zipcode: '',
      complement: '',
    }
  }
}

const computedForm = computed(async () => {

  if (formData.weight <= 0)
    return { missing: "Digite um peso maior que 0." };


  if (!selectedOption.value.profile._id) {
    return {
      missing: "Selecione uma transportadora."
    };
  }

  const isContentSection = section.value === "content";

  return {
    weight: formData.weight,
    status: 'paid',
    receiver_name: formData.receiver_name,
    receiver_document: formData.receiver_document,
    content_description: isContentSection ? formData.content_description : null,
    amount: formData.declared_value,
    products: !isContentSection ? selectedProducts.value.map((plan: any) => {
      return {
        name: plan?.product.name,
        code: plan?.product.code,
        plans: {
          name: plan?.name,
          code: plan?.code,
          quantity: plan?.quantity
        }
      }
    }) : [],
    customer: {
      name: formData.receiver_name,
      document: formData.receiver_document,
      email: formData.receiver_email,
      phone: formData.receiver_phone
    },
    shipping_address: {
      address: formData.receiver_address,
      city: formData.receiver_city,
      complement: formData.receiver_complement,
      country: formData.receiver_country,
      district: formData.receiver_district,
      number: formData.receiver_number,
      state: formData.receiver_state,
      zipcode: formData.receiver_zipcode,
    },
    shipping_provider: selectedOption.value.provider,
    shipping_profile: selectedOption.value.profile,
    shipping_cost: selectedOption.value.cost,
    shipping_service: selectedOption.value.service._id,
    is_reshipment: formData.is_reshipment,
    ...(formData.is_reshipment === true && {
      token: formData.order_token,
      integration: selectedIntegrations.value[0]
    }),
  }
})

const selectedOption = ref(freshOption)

watchEffect(async () => {
  if (!formData.weight || !formData.receiver_zipcode) {
    return
  }

  selectedOption.value = freshOption

  const { result } = await shippingTableStore.$functions.simulate({
    zipcode: formData.receiver_zipcode,
    weight: formData.weight,
  })

  if (result) {
    simulation.value = result
  }
})

const handlePost = async () => {
  const computed = await computedForm.value;

  if (computed.missing) {
    spawnModal(computed.missing);
    return;
  }

  //Assegura que o shipmentId seja null pra não aparecer o botão de baixar etiqueta
  shipmentId.value = '';

  isWaiting.value = true
  try {
    const { error, result } = await aeria.shippingTable.manualPost.POST(computed)

    if (error) {
      if (error.code === 'null_value') {
        spawnModal(`Erro: o campo "${t(error.details as string)}" está vazio.`);
      } else if (error.code === 'invalid_value') {
        spawnModal(`Erro: o valor do campo "${t(error.details as string)}" é inválido.`);
      } else {
        const message = error.message ?? error.code
        spawnModal(message ?? "Erro desconhecido");
      }

      isWaiting.value = false
      return
    }

    if (result.success && !computed.missing) {
      if (result.error_message) {
        metaStore.$actions.spawnModal({
          title: `Postagem realizada com erro`,
          body: result.error_message
        })
      } else {
        metaStore.$actions.spawnToast({
          text: computed.is_reshipment === true ? 'Pedido reenviado e notificação enviada' : 'Pedido postado e notificação enviada',
        })
      }

    }
    shipmentId.value = result.shipment_id

  } catch (error: any) {
    spawnModal(error?.message || 'Try catch error')
    console.error(error)
  }

  isWaiting.value = false
}

const spawnModal = (text: string) => {
  metaStore.$actions.spawnModal({
    title: 'Ops...',
    body: t(text, { capitalize: true }),
  })
}

const openLabel = () => {
  window.open(`${API_URL}/shipment/downloadLabels/${shipmentId.value}`)
}

const clearFields = () => {
  if (Object.keys(query).length > 0) {
    window.location.href = window.location.origin + window.location.pathname
    return
  }

  selectedOption.value = freshOption
  selectedProducts.value = []
  simulation.value = []
  shipmentId.value = null

  for (const key in formData) {
    (<any>formData)[key] = (<any>defaultFormData)[key]
  }

  inputRenderer.value++
}

const isWaiting = ref(false)

const getShippingTax = (cost: number) => {
  return cost > 0 ? ((VITE_PROFIT_PERCENTAGE * cost) / 100) : 0
}
</script>

  <template>
    <div class="
      tw-flex
      tw-flex-col
      lg:tw-flex-row
      tw-gap-8
    ">
      <aeria-form
        v-model="formData"
        :key="inputRenderer"
        class="posting-form"  
        style="
          --form-internal-gap: 0;
        "
      >
        <fieldset class="
          tw-grid
          tw-grid-cols-3
          tw-gap-4
        ">
          <aeria-input
            v-model="formData.receiver_zipcode"
            :property="{
              type: 'string',
              mask: '#####-###',
            }"
          >
            Código postal
          </aeria-input>
          <aeria-input :property="{
            type: 'number',
            placeholder: '0',
          }" v-model="formData.weight">
            Peso (em gramas)
          </aeria-input>
          <aeria-input :property="{
            type: 'number',
            placeholder: '0',
          }" v-model.number="formData.declared_value">
            Valor declarado (R$)
          </aeria-input>
          <div class="tw-flex tw-flex-row tw-items-center">
            <h4 class="tw-mr-2 tw-my-6">Reenvio</h4>
            <input class="tw-max-w-10" type="checkbox" v-model="formData.is_reshipment"> 
          </div>
          <aeria-input v-if="formData.is_reshipment === true" :property="{
            type: 'string',
            placeholder: '(Opcional)'
          }" v-model="formData.order_token">
            Token do pedido
          </aeria-input>
          <div v-show="formData.is_reshipment === true && formData.order_token?.length > 0">
            <document-selector
              class="tw-w-[-webkit-fill-available] tw-max-w-[200pt] tw-mt-[1.1rem]"
              collection="integrations"
              :single="true"
              default-value="Nenhuma integração"
              :property="{
                  $ref: 'integration',
                  indexes: [
                      'name',
                      'platform'
                  ]
              }" 
              :current-documents="selectedIntegrations" 
              @onSelectionChange="(newSelection: any) => selectedIntegrations = newSelection">
              <div class="tw-mt-[-0.2rem]">Integração:</div>
            </document-selector>
          </div>
        </fieldset>

        <fieldset class="
          tw-flex
          tw-flex-col
          tw-gap-4
        ">
          <aeria-tabs query="modality">
            <template #content>Conteúdo</template>
            <template #products>Produtos</template>
          </aeria-tabs>
          
  
          <div v-if="$route.query.modality === 'products'" class="
            tw-flex
            tw-flex-col
            tw-gap-4
          ">
            <document-selector 
              collection="products"
              :property="{
                type: 'array',
                items: {
                $ref: 'productPlan',
                indexes: [
                    'name',
                    'code'
                ]
              }
            }" 
            :current-documents="selectedProducts" 
            @onSelectionChange="(newSelection: any) => selectedProducts = newSelection">
              Selecionar produtos
            </document-selector>
          </div>
  
          <div v-else class="
            tw-grid
            tw-grid-cols-3
            tw-gap-4
          ">
           <aeria-input v-model="formData.content_description">Conteúdo</aeria-input>
  
            <aeria-input :property="{
              type: 'string',
              mask: '#.###'
            }" v-model="formData.height">Altura (metros)</aeria-input>
  
            <aeria-input :property="{
              type: 'string',
              mask: '#.###'
            }" v-model="formData.width">Largura (metros)</aeria-input>
          </div>

        </fieldset>
  
        <fieldset>
          <aeria-form
            v-model="formData"
            :form="{
              receiver_name: {
                type: 'string'
              },
              receiver_document: {
                type: 'string',
                mask: [
                  '###.###.###-##',
                  '###.###.###/####-##'
                ]
              },
              receiver_email: {
                type: 'string',
                inputType: 'email'
              },
              receiver_phone: {
                type: 'string',
                mask: '(##) #####-####'
              },
              receiver_address: {
                type: 'string'
              },
              receiver_number: {
                type: 'number'
              },
              receiver_district: {
                type: 'string'
              },
              receiver_city: {
                type: 'string'
              },
              receiver_state: {
                type: 'string',
                mask: '@@'
              },
              receiver_complement: {
                type: 'string',
                placeholder: '(Opcional)',
              },
            }"
            :required="['receiver_name', 'receiver_document', 'receiver_email', 'receiver_phone', 'receiver_address', 'receiver_number',
                        'receiver_district', 'receiver_city', 'receiver_state']"
            :layout="{
              fields: {
                $default: {
                  span: '3',
                }
              }
            }"
            >
          </aeria-form>
        </fieldset>
  
      </aeria-form>
  
      <div class="
        tw-flex
        tw-flex-col
        tw-flex-1
        tw-gap-6
        tw-min-w-[16rem]
      ">
        <div
          v-loading="shippingTableStore.loading.simulation"
          class="
            tw-flex-1
            tw-border
            tw-overflow-hidden
        ">
          <div
            v-clickable
            v-for="option in simulation"
            :key="`option-${option.profile._id}-${option.service._id}`"
            :class="`
              tw-flex
              tw-gap-2
              tw-items-center
              [&:not(:last-child)]:tw-border-b
              tw-p-4
              ${
                selectedOption.profile === option.profile && selectedOption.service === option.service
                  ? 'tw-bg-blue-100 dark:tw-bg-gray-500'
                  : ''
              }
            `"
  
            @click="selectedOption.profile === option.profile && selectedOption.service === option.service
              ? selectedOption = freshOption
              : selectedOption = option
            "
          >
            <extra-icon :name="option.profile.provider"></extra-icon>
            <div class="
              tw-flex
              tw-flex-col
              tw-gap-1
            ">
              <div>{{ option.profile.name }}</div>
              <div class="
                tw-text-[9pt]
                tw-opacity-80
              ">
                {{ option.service.description }}
              </div>
            </div>
            <div class="
              tw-ml-auto
              tw-text-[10pt]
              tw-font-[400]
            ">
              {{ formatCurrency(option.cost) }}
            </div>
  
          </div>
        </div>
  
        <div class="
          tw-border
          tw-p-6
          tw-space-y-2
          tw-justify-center
          tw-items-center
          tw-flex
          tw-flex-col
          [&>*]:tw-min-w-32
          [&>*]:tw-justify-center
          ">
          <div
            v-if="shipmentId"
            class="
              tw-flex
              tw-flex-col
              tw-gap-2
            "
          >
          <aeria-button
              large
              icon="cloud-arrow-down"
              :disabled="!shipmentId"
              v-if="shipmentId?.length === 24"
              @click.prevent="openLabel"
            >
              Baixar etiqueta
            </aeria-button>
  
            <aeria-button
              variant="transparent"
              @click.prevent="clearFields"
            >
              Limpar
            </aeria-button>
          </div>
          
          <div class="tw-flex tw-flex-col" v-else>
            <aeria-button
            large
            :disabled="isWaiting || !selectedOption.profile"
            @click.prevent="handlePost">
            {{ isWaiting ? "Aguarde..." : `Postar (${formatCurrency(selectedOption.cost + getShippingTax(selectedOption.cost)) })`}}
            </aeria-button>
            <h4 v-if="selectedOption.cost > 0" class="tw-text-center tw-m-0 tw-mt-2">+{{ formatCurrency(getShippingTax(selectedOption.cost)) }} ({{ VITE_PROFIT_PERCENTAGE }}% de taxa)</h4>
          </div>

        </div>
        
      </div>
    </div>
  </template>
  
  <style scoped lang="less">
  fieldset {
    padding: 1.6rem;
    &:not(:last-child) {
      border-bottom: 1px solid var(--theme-border-color);
    }
  }
  
  .posting-form {
    padding: 0;
    border: 1px solid var(--theme-border-color);
    border-width: 1px 1px 0 1px;
  }
  </style>../../../helpers
