<script setup lang="ts">
import General from './_general.vue'
import Invitations from './_invitations.vue'
import { getMyOrganization } from '../../../helpers'

definePage({
  meta: {
    title: 'Minha organização',
    icon: 'buildings',
  }
})

const userStore = useStore('user')

onMounted(async () => {
  const organization = await getMyOrganization(userStore.currentUser)
  if (!organization.me.roles) {
    ROUTER.push('/dashboad')
    return
  }

  if (!organization.me.roles.includes('manager')) {
    ROUTER.push('/dashboad')
    return
  }
})
</script>

<template>
  <general
    v-if="$route.params.section === 'general' || !$route.params.section"
  />
  <invitations v-else />
</template>
