<script setup lang="ts">
definePage({
  meta: {
    title: 'Início'
  }
})
</script>
<template>
  Em construção.
</template>
